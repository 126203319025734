import * as ErrorReporting from '@sentry/node'

if (process.env.NEXT_PUBLIC_DEPLOYMENT_ENV) {
  try {
    ErrorReporting.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_DEPLOYMENT_ENV,
    })
  } catch (err) {
    console.error('Sentry initialization error', err)
  }
}

export default ErrorReporting
