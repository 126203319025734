import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import Meta, {DEFAULTS} from 'modules/common/components/Meta'
import DefaultLayout from 'modules/common/components/Layout'

import Error404 from './404'
import InternalError from './5xx'

const ERROR_PAGES = {
  '404': Error404,
  '5xx': InternalError,
}

const ErrorPage = ({status, title}) => {
  const pageTitle = `${status} - ${title} | ${DEFAULTS.title}`
  const ErrorComponent = ERROR_PAGES[status] || ERROR_PAGES['5xx']

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <Meta />
      </Head>
      <DefaultLayout
        heroContainerClassName="is-fullheight"
        hero={<ErrorComponent status={status} title={title} />}
      />
    </>
  )
}

ErrorPage.defaultProps = {
  status: 500,
  title: 'Internal Server Error',
}

ErrorPage.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
}

export default ErrorPage
