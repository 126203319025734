import React from 'react'

const ServerError = props => {
  return (
    <>
      <div className="columns is-gapless is-mobile has-text-green has-text-centered">
        <div className="column">
          <h1 className="is-size-4 is-size-6-mobile has-letter-spacing">
            An error occurred, please try again later!
          </h1>
        </div>
      </div>
      <div className="columns is-centered is-vcentered is-gapless has-text-centered">
        <div className="column is-half">
          <figure className="image p-1">
            <img src="/assets/img/feeling-blue.svg" />
          </figure>
        </div>
      </div>
    </>
  )
}

ServerError.propTypes = {}

export default ServerError
