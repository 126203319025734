import React from 'react'
import PropTypes from 'prop-types'

export const DEFAULTS = {
  title: 'reden.me',
  company: 'reden.me',
  url: 'https://reden.me',
  description:
    'Free and encrypted chat, no login, no installations needed. Create unlimited encrypted chat links for free and start chatting with anyone.',
  shortDescription:
    'Free and encrypted chat, no login, no installations needed!',
  keywords:
    'reden.me, free chat, group chat, encrypted chat, chatroom, chat link, chat without app, chat without account',
  image: {
    width: '1200',
    height: '800',
    type: 'image/jpeg',
    href: '/assets/img/meta.jpg',
  },
}

const Meta = ({
  title,
  company,
  description,
  keywords,
  url,
  image,
  additionalMeta,
}) => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />

    {/* Generic OG Tags */}
    <meta property="og:site_name" content={company} />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image.href} />
    <meta property="og:image:width" content={image.width} />
    <meta property="og:image:height" content={image.height} />

    {/* Twitter Tags */}
    <meta name="twitter:site" content={url} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content={image.href} />

    {/* Additional meta info */}
    {additionalMeta &&
      additionalMeta.forEach(({name, property, content}) => (
        <meta
          name={name}
          property={property}
          content={content}
          key={`${name}-${property}-${content}`}
        />
      ))}
  </>
)

Meta.defaultProps = {
  title: DEFAULTS.title,
  company: DEFAULTS.company,
  description: DEFAULTS.description,
  keywords: DEFAULTS.keywords,
  url: DEFAULTS.url,
  image: DEFAULTS.image,
}

Meta.propTypes = {
  additionalMeta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      property: PropTypes.string.isRequired,
    })
  ),
  company: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
  keywords: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default Meta
