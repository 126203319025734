import React from 'react'

const NotFound = props => {
  return (
    <div className="columns is-centered is-vcentered is-gapless has-text-centered">
      <div className="column is-half">
        <figure className="image p-1">
          <img src="/assets/img/page-not-found.svg" />
        </figure>
      </div>
    </div>
  )
}

NotFound.propTypes = {}

export default NotFound
